export const MUNIS = {
    bel: { value: 'bel', name: 'Bourg-en-Lavaux', svg: '/img/coat-of-arms/bel.svg', png: '/img/coat-of-arms/bel.png' },
    chexbres: { value: 'chexbres', name: 'Chexbres', svg: '/img/coat-of-arms/chexbres.svg', png: '/img/coat-of-arms/chexbres.png' },
    forel: { value: 'forel', name: 'Forel', svg: '/img/coat-of-arms/forel.svg', png: '/img/coat-of-arms/forel.png' },
    puidoux: { value: 'puidoux', name: 'Puidoux', svg: '/img/coat-of-arms/puidoux.svg', png: '/img/coat-of-arms/puidoux.png' },
    rivaz: { value: 'rivaz', name: 'Rivaz', svg: '/img/coat-of-arms/rivaz.svg', png: '/img/coat-of-arms/rivaz.png' },
    stSaph: { value: 'stSaph', name: 'Saint-Saphorin', svg: '/img/coat-of-arms/st-saph.svg', png: '/img/coat-of-arms/st-saph.png' },
    savigny: { value: 'savigny', name: 'Savigny', svg: '/img/coat-of-arms/savigny.svg', png: '/img/coat-of-arms/savigny.png' },
};

export const STATIONS = {
    cully: { value: 'cully', name: 'Cully', town: 'cully' },
    forel: { value: 'forel', name: 'Forel', town: 'forel' },
};

export const TOWNS = {
    aran: { value: 'aran', name: 'Aran', npa: '1091', muni: 'bel' },
    chbrs: { value: 'chbrs', name: 'Chexbres', npa: '1071', muni: 'chexbres' },
    cully: { value: 'cully', name: 'Cully', npa: '1096', muni: 'bel' },
    eps: { value: 'eps', name: 'Epesses', npa: '1098', muni: 'bel' },
    forel: { value: 'forel', name: 'Forel', npa: '1072', muni: 'forel' },
    gdvx: { value: 'gdvx', name: 'Grandvaux', npa: '1091', muni: 'bel' },
    pdx: { value: 'pdx', name: 'Puidoux', npa: '1070', muni: 'puidoux' },
    riex: { value: 'riex', name: 'Riex', npa: '1097', muni: 'forel' },
    rivaz: { value: 'rivaz', name: 'Rivaz', npa: '1071', muni: 'rivaz' },
    svgy: { value: 'svgy', name: 'Savigny', npa: '1073', muni: 'savigny' },
    stSaph: { value: 'stSaph', name: 'St-Saphorin', npa: '1071', muni: 'stSaph' },
    vlte: { value: 'vlte', name: 'Villette', npa: '1091', muni: 'bel' },
};